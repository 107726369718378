import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import CustomLink from "../../components/CustomLink"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"

const ThePatientsDashboardPage = () => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">Understanding the Profile Dashboard</h3>

              <p>
                Take control of your personal and business information, specify
                production and treatment preferences, and add new account users
                to streamline your experience with the Profile Dashboard.
              </p>

              <div className="mb-4" />

              <p>
                Once you click on Profile, it will take you to the User
                Information section in the Profile page, where you can view the
                details regarding your account that you entered at sign-up. You
                can edit it here if you wish to update.
              </p>

              <div className="mb-4" />

              <h5 id="Adding Business Details">
                Title: <strong>Adding Business Details</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Navigate to the Profile page by
                clicking on Profile on the left navigation pane in the main
                dashboard.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the top navigation, click on
                Business Details and Users.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Fill in the preliminary details
                regarding your Business Details and Users and click on update.
                Users are also asked to add business details details after
                subscribing but they can choose to do it later as well.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/17.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - You can also add in Additional User
                Accounts through this section as well.
              </p>

              <div className="mb-4" />

              <h5 id="Additional User Accounts">
                Title: <strong>Additional User Accounts</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - Navigate to the Profile page by
                clicking on Profile on the left navigation pane in the main
                dashboard.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the top navigation, click on
                Business Details and Users.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - Right after your business details, you
                will see the Business Details and Users section. Here you can
                click on "Additional User Accounts".
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/17b.jpg" />

              <div className="mb-4" />

              <p>
                <strong>Step 4</strong> - After clicking on Add new user
                accounts, fill in the details for the user. Mandatory
                information includes, title, full name and an email address.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 5</strong> - Once you add a new user, the user then
                has to accept the invite by checking his / her email and
                clicking on the activation link.
              </p>

              <div className="mb-4" />

              <p>
                It is important to note that the managing account will be the
                first one who has added other users. The billing and access
                management of user will be only through the main account.
              </p>

              <div className="mb-4" />

              <h5 id="Understanding and Updating Preferences">
                Title: <strong>Understanding and Updating Preferences</strong>
              </h5>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/18.jpg" />

              <div className="mb-4" />

              <p>
                For each user, there are unique preferences that can be set
                through the Preferences section within the Profile page.
              </p>

              <div className="mb-4" />

              <p>
                These preferences are important to set before placing an order
                as these will affect the overall treatment plan outcome.{" "}
              </p>

              <div className="mb-4" />

              <p>
                The preferences are further divided into following sections:
              </p>

              <div className="mb-4" />

              <strong>Production Preferences</strong>

              <div className="mb-4" />

              <ul class="list-disc pl-4">
                <li>
                  Print Ready STL Files
                  <ul class="list-circle pl-4">
                    <li>
                      Users can choose:
                      <ul class="list-square pl-4">
                        <li>Solid Models</li>
                        <li>Solid Models with Support Bar</li>
                        <li>Hollow Models with Open Base</li>
                        <li>Open Hollow Models with Support Bar</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  Tray Number On Teeth
                  <ul class="list-circle pl-4">
                    <li>
                      Users can choose:
                      <ul class="list-square pl-4">
                        <li>On First Molar</li>
                        <li>On Second Molar</li>
                        <li>Add Tray Number to Model ID Tag</li>
                        <li>No Tray Number on Teeth</li>
                      </ul>
                    </li>
                  </ul>
                </li>
                <li>
                  ID Tag Placement
                  <ul class="list-circle pl-4">
                    <li>
                      Users can choose:
                      <ul class="list-square pl-4">
                        <li>Base - Patients Right Quadrant</li>
                        <li>At the bottom of the model</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  ID Tag Text
                  <ul class="list-circle pl-4">
                    <li>
                      Users can choose:
                      <ul class="list-square pl-4">
                        <li>Patient Full Name</li>
                        <li>No ID Tag</li>
                        <li>First Name + Step Number + U/L Indicator</li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>

              <div className="mb-4" />

              <strong>Treatment Preferences</strong>

              <div className="mb-4" />

              <p>
                These preferences are focused on how AlignerBase technicians
                proceed with your orders. The information set here will let our
                technicians know how to setup your treatment plans.
              </p>

              <div className="mb-4" />

              <p>
                Before you place an order, we highly suggest you set these
                preferences to your desired options.
              </p>

              <div className="mb-4" />

              <p>
                Below, you can see a complete list of treatment preferences
                available for you to configure.
              </p>

              <div className="mb-4" />

              <ul class="list-disc pl-4">
                <li>
                  <strong>Movement Velocity:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Clinicians can modify and set the movement velocity
                      according to their preferred requirements.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Standard movement per tooth, per
                          step - 0.3 mm translation, intrusion, and extrusion. 3
                          degrees rotation
                        </li>
                        <li>
                          Option 2: Reduced movement per tooth, per step (will
                          result in an increase in the # of aligners) - 0.2 mm
                          translation, intrusion, and extrusion. 2 degrees
                          rotation.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Wear Schedule:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Depending upon the individual circumstances, a preference
                      of either 1-, 2-, or 3-week wear schedule can be set.
                      <ul class="list-square pl-4">
                        <li>Option 1 (Default): 2 Weeks</li>
                        <li>Option 2: 1 Week</li>
                        <li>Option 2: 3 Weeks</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>IPR Timing:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      IPR can be set only on odd steps when interproximal
                      surfaces are positioned properly.
                      <ul class="list-square pl-4">
                        <li>Option 1 (Default): Delay IPR to step 3</li>
                        <li>No delay for IPR</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>IPR Maximum:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      If the amount of IPR performed is less, you will have more
                      predictable control over tooth movement.
                      <ul class="list-square pl-4">
                        <li>Option 1 (Default): 0.60 mm for all teeth</li>
                        <li>
                          Option 2: 0.30 mm for anterior teeth, 0.60 mm for
                          posterior teeth
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Anterior Torque:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Anterior torque is the rotation of an anterior tooth on
                      its long axis, especially the movement of the apical
                      portions of the teeth by use of orthodontic appliances
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Standard cases: apply
                          approximately 10 degrees of positive torque - Class
                          III cases: retrocline lower anteriors with IPR,
                          overcorrect upper anterior positive torque
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Posterior Torque:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Posterior torque is the rotation of a posterior tooth on
                      its long axis, especially the movement of the apical
                      portions of the teeth by use of orthodontic appliances.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Minimal change only to improve
                          occlusion
                        </li>
                        <li>
                          Option 2: Upright upper posterior teeth and apply
                          slight negative torque on lower posteriors.
                        </li>
                        <li>
                          Option 3: Upright lower posterior teeth and apply
                          slight positive torque on upper posteriors.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Expansion:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      The better/more anchorage the more expansion that can be
                      achieved. Premolars biologically allow for more expansion
                      than molars.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Expansion of canines to 1st molars
                          combined with anterior protrusion. No expansion in 2nd
                          & 3rd molars. Maximum 2 mm per quadrant
                        </li>
                        <li>
                          Option 2: Expansion of canines to premolars and hold
                          other teeth as anchors. Maximum 3mm per quadrant.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Class II Corrections:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      If the mesial-buccal cusp of the upper first molar is
                      anterior to this groove, by the width of a premolar of
                      more, it's considered a Class II molar relationship
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Upper molar distalization with
                          sequential movement
                        </li>
                        <li>
                          Option 2: If correction is planned with elastics or
                          surgery, move molars to Class I occlusion before the
                          start of the setup using Arch Correction Simulation.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Smile Arc:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      The Smile Arc (how the biting edges of the upper teeth
                      'flow' along the curvature of the lower lip line) can be
                      used as guidance to help establish an ideal for how the
                      teeth should look when the patient smiles.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Follow lip guidance based on
                          frontal smiling picture
                        </li>
                        <li>
                          Option 2: Align and level following ideal occlusion,
                          no lip guidance
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Occlusion:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      The ideal goal for occlusion is to have a cusp-fossa
                      relationship when the teeth are brought into contact.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Cusp to fossa occlusion with
                          light, even contacts throughout the arch
                        </li>
                        <li>
                          Option 2: Light contacts in the posterior with no
                          anterior contacts.
                        </li>
                        <li>
                          Option 3: Heavy contacts in the posterior with no
                          anterior contacts.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Curve of Spee:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Curve of Spee is the curvature of the mandibular occlusal
                      plane beginning at the canine and following the buccal
                      cusps of the posterior teeth, continuing to the terminal
                      molar. Correction of the Curve of Spee is up to the
                      Clinician & the treatment goals for the case.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Idealize Curve of Spee by
                          combination of tipping, intrusion, and extrusion
                        </li>
                        <li>
                          Option 2: Improve occlusion, but do not correct Curve
                          of Spee unless requested on prescription
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Virtual C Chain:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      A Virtual C-Chain is where any existing spaces (at a
                      specific step of treatment) are closed. Virtual C-Chains
                      are usually planned and used at the end of treatment.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Place only when requested on
                          prescription. Default placement on last 2 steps.
                        </li>
                        <li>
                          Option 2: Perform Virtual C-Chain on all cases to
                          tighten spaces.
                        </li>
                        <li>
                          Option 3: When performing a Virtual C-Chain, remove
                          attachments before C-Chain steps.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>First Molars:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      First Molars play a pivotal role in the maintenance of the
                      arch form and proper occlusal schemes. Establishing a
                      preference for the approach you would like to take for
                      first molars in your treatment setups is important for
                      treatment planning purposes.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Improve if needed to establish
                          better occlusion
                        </li>
                        <li>Option 2: Do not move 1st molars</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Second & Third Molars:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Molars (depending on the amount and required movements
                      needed) can be difficult to move with clear aligners
                      alone. It's important to have a clear plan in your
                      treatment preferences for how you want to approach 2nd and
                      3rd molars in your treatment setups.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Improve if needed to establish
                          better occlusion
                        </li>
                        <li>
                          Option 2: Do not move 2<sup>nd</sup> & 3<sup>rd</sup>{" "}
                          molars
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Overjet & Overbite:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Overjet is an increased projection of the upper teeth in
                      front of the lower teeth, usually measured parallel to the
                      occlusal plane.
                      <br />
                      Overbite is an increased vertical overlapping of the
                      mandibular anterior teeth by the maxillary anterior teeth,
                      usually measured perpendicular to the occlusal plane.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Set to ideal overjet (1.5 - 2 mm)
                          with light contacts
                        </li>
                        <li>
                          Option 2: Set overjet to (2 - 3 mm) but with slight
                          overbite and no anterior contacts
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Mild - Moderate Crowding:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Crowding is a condition where the teeth are too close
                      together & have abnormal positions such as overlapping,
                      displacement in various directions, or torsion.
                      <br />
                      <br />
                      Up to 3mm of crowding is considered mild and beyond 6mm is
                      considered severe.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Expand canines & premolar regions,
                          place IPR as needed, and apply anterior protrusion.
                        </li>
                        <li>
                          Option 2: Expand canines, premolar regions, and first
                          molars. Place IPR as needed, but no anterior
                          protrusion.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Moderate - Severe Crowding (Class II):</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Crowding is a condition where the teeth are too close
                      together & have abnormal positions such as overlapping,
                      displacement in various directions, or torsion.
                      <br /> <br />
                      Beyond 6mm of crowding is considered severe.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Expand canines & premolar regions,
                          & first molars distalization of up to 1-2mm no 3rd
                          molars. Place IPR as needed, & place anterior round
                          tripping as needed.
                        </li>
                        <li>
                          Option 2: Expand canines & premolar regions, and first
                          molars distalization of up to 1-2 mm, no 3rd molars.
                          Place IPR as needed, but no anterior round tripping.
                        </li>
                        <li>
                          Option 3: Expand canines & premolar regions, and first
                          molars distalization of up to 1-2mm no 3rd molars.
                          Place anterior round tripping as needed, but no IPR.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Engager Protocols - Deep Bite:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Some malocclusions need more anchorage, so we provide
                      alternative options for placing engagers on the teeth for
                      better control when needed.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Rectangular engagers on 1 and 2
                          premolars and no engagers on anterior teeth
                        </li>
                        <li>
                          Option 2: Engagers on anterior teeth and no engagers
                          on posterior teeth.
                        </li>
                        <li>Option 3: No engagers at all.</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Engager Protocols - Open Bite:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Some malocclusions need more anchorage, so we provide
                      alternative options for placing engagers on the teeth for
                      better control when needed.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Rectangular engagers on anterior
                          teeth and no engagers on posterior
                        </li>
                        <li>
                          Option 2: Engagers on premolars, canines and incisors
                        </li>
                        <li>Option 3: No engagers at all.</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Engager Timing:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      The step where engagers will begin being placed in the
                      treatment.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Delay and place at step 3 and keep
                          until end of each arch's treatment
                        </li>
                        <li>
                          Option 2: Place only when requested on prescription
                        </li>
                        <li>
                          Option 3: Place at step 1 and keep all engagers until
                          end of treatment.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Engager Size:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      ClearCorrect offers different engager sizes for better fit
                      according to the anatomy of the tooth and to improve
                      anchorage.
                      <ul class="list-square pl-4">
                        <li>Option 1 (Default): 3mm</li>
                        <li>Option 2: 2mm</li>
                        <li>Option 3: 4mm</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Engager Beveling:</strong>
                  <ul class="list-circle pl-4">
                    <li>
                      Beveling is applied to redirect force and tooth movement.
                      Depending on where the beveling is placed you can redirect
                      the alignment of force for tooth movement.
                      <ul class="list-square pl-4">
                        <li>
                          Option 1 (Default): Position beveled edge towards
                          incisal.
                        </li>
                        <li>Option 2: [Free text field] Additional notes.</li>
                      </ul>
                    </li>
                  </ul>
                </li>

                <li>
                  <strong>Additional Treatment Preferences Notes:</strong>
                </li>
              </ul>

              <div className="mb-4" />

              <h5 id="How to Change Password">
                Title: <strong>How to Change Password</strong>
              </h5>

              <div className="mb-4" />

              <p>
                <strong>Step 1</strong> - In order to change your password, you
                must be logged in.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 2</strong> - From the main dashboard through the
                left navigation pane, click on <strong>Change Password</strong>.
              </p>

              <div className="mb-4" />

              <p>
                <strong>Step 3</strong> - You would now be required to input
                your existing password as well as the new one.
              </p>

              <div className="mb-4" />

              <p>For security, your password must have:</p>

              <ul className="list-disc ml-12">
                <li>Minimum 8 characters</li>
                <li>A special character (!@#$%^&*)</li>
                <li>A capital (uppercase) letter (A-Z)</li>
                <li>A number (0-9)</li>
              </ul>
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default ThePatientsDashboardPage
